import React from "react";

export default function YopiqLanding() {
  return (
    <h2
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Доступ к системе временно приостановлен из-за неуплаты за оказанные услуги.
    </h2>
  );
}
